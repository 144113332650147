
import { mapGetters, mapActions } from "vuex";
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
// import {i18n} from '@/i18n';
import i18n from "@/vueI18n";
// import firebase from 'firebase/app';
import "firebase/firestore";
import FilterFacilities from "@/modules/facilities/components/filter-facilities.vue";
import FacilitiesFilterModal from "@/modules/facilities/components/facilities-filter-modal.vue";

// import facilitiesListVue from '../../../../../dashboard/src/modules/properties/components/facilities-list.vue';
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
import moment from "moment";

export default {
  name: "app-facilities-list-table",
  data() {
    return {
      // listedFor:[],
      // facilityListedFor:null,
      modalFilterVisible: false,
      dialogFilterVisible: false,
      topOfPage: true,

      search: "",
      filter: "",
      page: 1,
      rowsOld: [],
      searchByOptions: ["city", "listedFor", "type"],

      filterSearch: false,
      facilitiesReady: false,
      facilitiesListedFor: [],
      properties: [],
      propertiesWithoutFilter: [],
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
      },
      propertiesWithCreators: [],
      // loading:false,
      searchModel: "",
      searchOption: "",
      searchOptionFilter: "",
      searchInputAnimation: false,
      deleteDialogHeader: false,
      loadingTable: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      mountedTable: "facilities/list/mountedTable",
      facilities: "facilities/list/facilities",
      facilitiesWithoutFilter: "facilities/list/facilitiesWithoutFilter",
      currentUserFacilities: "facilities/list/currentUserFacilities",
      loading: "facilities/list/loading",
      currentRoute: "facilities/list/currentRoute",
      is_screen_xs: "layout/is_screen_xs",
      mountedCityTable: "facilities/list/mountedCityTable",
      mountedFacilityTypeTable: "facilities/list/mountedFacilityTypeTable",
      mountedFacilityListedfForTable:
        "facilities/list/mountedFacilityListedfForTable",

      cities: "facilities/list/cities",
      listedFor: "facilities/list/facilityListedFor",
      types: "facilities/list/facilityTypes",

      //#region [vuexfire]
      rows: "facilities/list/rows",
      rowsFromRoute: "facilities/list/rowsFromRoute",
      recordBeforeFirst: "facilities/list/recordBeforeFirst",
      recordAfterLast: "facilities/list/recordAfterLast",
      addedCreatorId: "facilities/list/addedCreatorId",
      userListener: "auth/userListener",
      loadingAddMoreFacilities: "facilities/list/loadingAddMoreFacilities",
      //#endregion

      detailsLoading: "facilities/list/detailsLoading",
      is_screen_md: "layout/is_screen_md",
    }),
    isRTL() {
      return i18n.locale == "ar";
    },
    rowsPerPageOptions() {
      return [2];
    },
    isScreenXs() {
      if (
        this.$q.screen.lt.lg &&
        this.$q.screen.lt.md &&
        this.$q.screen.lt.sm &&
        this.$q.screen.lt.xl
      ) {
        return true;
      }
      return false;
    },
    rowsToBeDisplayed() {
      if (this.rows.length > 0 || !this.loading) {
        return this.rows;
      } else {
        return this.rowsOld;
      }
    },
  },
  watch: {
    rows(newval) {
      // debugger
      if (newval.length > 0) {
        // this.rowsOld = newval
        this.setLoadFirstRecord();
        this.setLoadMoreLast();
        this.setDisablePrevious({
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
        this.setDisableNext({
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
      }
    },
    addedCreatorId(newval) {
      if (newval) {
        this.setCreatorToRow();
      }
    },
    rowsFromRoute(change) {
      const page = this.$route.query.page;
      const limit = this.getItemsPerPage();
      const rowsFromRouteToBeDisplayed = change.slice(
        page * limit - limit,
        page * limit + 1
      );
      this.setRows(rowsFromRouteToBeDisplayed);
    },
  },
  components: {
    [DeleteEdit.name]: DeleteEdit,
    [FilterFacilities.name]: FilterFacilities,
    [FacilitiesFilterModal.name]: FacilitiesFilterModal,
  },
  methods: {
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    descriptionPresenter(value) {
      var description = "";
      if (!this.is_screen_md) {
        description = value.toString().slice(0, 40);
        description = value.length > 40 ? description + "..." : description;
      } else {
        description = value.toString().slice(0, 30);
        description = value.length > 30 ? description + "..." : description;
      }
      return description;
      // return value
    },
    datePresenter(date) {
      const language = localStorage.getItem("language");
      return moment(new Date(date.seconds * 1000))
        .locale(language)
        .format("DD-MM-yyyy hh:mm a");
      // let dateUpdated = new Date(date.seconds * 1000).toString().slice(0, 15);
      // return dateUpdated;
    },
    openFilterModal() {
      this.modalFilterVisible = true;
      this.dialogFilterVisible = true;
    },
    closeFilterModal(value) {
      this.closeFilter(value);
      this.dialogFilterVisible = false;
      setTimeout(() => {
        this.modalFilterVisible = false;
      }, 500);
    },
    addNewItem() {
      this.$emit("addNewItem");
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      doFetch: "facilities/list/doFetch",
      doFetchFromRoute: "facilities/list/doFetchFromRoute",
      getPreviousRecordsBatch: "facilities/list/getPreviousRecordsBatch",
      setMountedTable: "facilities/list/setMountedTable",

      doFetchCity: "facilities/list/doFetchCity",
      doFetchFacilityType: "facilities/list/doFetchFacilityType",
      doFetchFacilityListedFor: "facilities/list/doFetchFacilityListedFor",
      setMountedCityTable: "facilities/list/setMountedCityTable",
      setMountedFacilityTypeTable:
        "facilities/list/setMountedFacilityTypeTable",
      setMountedFacilityListedForTable:
        "facilities/list/setMountedFacilityListedForTable",

      resetCursor: "facilities/list/resetCursor",
      setLoadFirstRecord: "facilities/list/setLoadFirstRecord",
      setLoadMoreLast: "facilities/list/setLoadMoreLast",
      setDisablePrevious: "facilities/list/setDisablePrevious",
      setDisableNext: "facilities/list/setDisableNext",
      setCreatorToRow: "facilities/list/setCreatorToRow",
      setRows: "facilities/list/setRows",

      setFacilityDetailsStarted: "facilities/list/setFacilityDetailsStarted",
      setFacilityDetailsSuccess: "facilities/list/setFacilityDetailsSuccess",
    }),
    scrollHandler(scroll) {
      if (this.currentRoute == "facilities") {
        if (scroll.position == 0 && !this.deleteDialogHeader) {
          this.setHeaderBg("bg-transparent");
          this.topOfPage = true;
        }
        if (scroll.position > 0 && !this.deleteDialogHeader) {
          this.topOfPage = false;
          this.setHeaderBg("bg-shadow");
          this.searchInputAnimation = false;
          this.$emit("changeFacilityHeader", false);
        }
        // if(scroll.position > 0 && this.deleteDialogHeader){
        //     this.setHeaderBg('bg-shadow')
        // }
        if (scroll.position == 0 && this.deleteDialogHeader) {
          this.setHeaderBg("bg-shadow");
        }
      }
    },
    checkOption(value) {
      if (this.searchModel === "city") {
        if (value.cityId.includes(this.searchOption)) {
          return value;
        }
      }
      if (this.searchModel === "facilityListedFor") {
        if (value.listedForId.includes(this.searchOption)) {
          return value;
        }
      }
      if (this.searchModel === "facilityType") {
        if (value.typeId.includes(this.searchOption)) {
          return value;
        }
      }
    },

    searchInFacility() {
      if (this.currentRoute != "my-facilities") {
        const ele = document.getElementById("header");
        const target = getScrollTarget(ele);
        const offset = ele.offsetTop;
        const duration = 0;
        setScrollPosition(target, offset, duration);
        this.searchInputAnimation = true;
        this.showFilter = true;
        this.$emit("changeFacilityHeader", true);
      }
    },

    async closeFilter(value) {
      // debugger
      if (value) {
        this.filterSearch = !this.filterSearch;
        this.resetCursor();
        this.page = 1;

        if (value.model) {
          this.searchModel = value.model;
          this.searchOption = value.id;
          if (this.searchModel === "city") {
            this.search = "city";
            this.filter = this.searchOption;
            let city = this.cities.filter(
              (city) => city.id == this.searchOption
            );
            this.searchOptionFilter = city[0];
            await this.doFetch({
              currentRoute: this.currentRoute,
              currentUser: this.currentUser,
              limit: this.getItemsPerPage(),
              searchBy: "city",
              filter: this.searchOption,
            });
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
          }
          if (this.searchModel === "facilityListedFor") {
            this.search = "listedFor";
            this.filter = this.searchOption;
            let listedForName = this.listedFor.filter(
              (listedForItem) => listedForItem.id == this.searchOption
            );
            this.searchOptionFilter = listedForName[0];
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
            this.doFetch({
              limit: this.getItemsPerPage(),
              searchBy: "listedFor",
              filter: this.searchOption,
            });
          }
          if (this.searchModel === "facilityType") {
            this.search = "type";
            this.filter = this.searchOption;
            let typeName = this.types.filter(
              (type) => type.id == this.searchOption
            );
            this.searchOptionFilter = typeName[0];
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
            this.doFetch({
              limit: this.getItemsPerPage(),
              searchBy: "type",
              filter: this.searchOption,
            });
          }
        } else {
          this.search = null;
          this.filter = null;
          this.$router.push({
            query: {
              page: this.page,
            },
          });
          this.doFetch({
            limit: this.getItemsPerPage(),
            currentRoute: this.currentRoute,
            currentUser: this.currentUser,
          });
        }
        this.rowsOld = this.rows;
      }
    },
    openFilter() {
      this.filterSearch = !this.filterSearch;
    },
    getItemsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      if (screen.lt.xl) {
        return 9;
      }
      return 9;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    openDeleteDialog() {
      this.deleteDialogHeader = true;
    },
    async closeDeleteDialog() {
      this.deleteDialogHeader = false;
      // this.setRows([]);
      // this.resetCursor();
      // this.page = 1
      // this.$router.push({
      //     query: {
      //         page: this.page
      // }
      // })
      // await this.doFetch({ page: 1, limit: this.getItemsPerPage(), currentUser: this.currentUser, currentRoute: this.currentRoute });
    },
    async doRefreshRows() {
      this.closeDeleteDialog();
      this.setRows([]);
      this.resetCursor();
      this.page = 1;
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      await this.doFetch({
        page: 1,
        limit: this.getItemsPerPage(),
        currentUser: this.currentUser,
        currentRoute: this.currentRoute,
        searchBy: this.search,
        filter: this.filter,
      });
    },
    async loadMore() {
      this.page = parseInt(this.page) + 1;
      if (this.search) {
        await this.doFetch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
        this.$router.push({
          query: {
            page: this.page,
            searchBy: this.search,
            filter: this.filter,
          },
        });
      } else {
        this.$router.push({
          query: {
            page: this.page,
          },
        });
        await this.doFetch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
        });
      }
      this.rowsOld = this.rows;
    },
    async goToPreviousPage() {
      this.page = parseInt(this.page) - 1;
      if (this.search) {
        await this.getPreviousRecordsBatch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
        this.$router.push({
          query: {
            page: this.page,
            searchBy: this.search,
            filter: this.filter,
          },
        });
      } else {
        await this.getPreviousRecordsBatch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
        });
        this.$router.push({
          query: {
            page: this.page,
          },
        });
      }
      this.rowsOld = this.rows;
    },
    async navigateBackwardForward() {
      if (
        this.$route.name == "facilities" ||
        this.$route.name == "my-facilities"
      ) {
        this.search = this.$route.query.searchBy;
        this.filter = this.$route.query.filter;
        this.page = parseInt(this.$route.query.page);

        this.setRows([]);
        this.resetCursor();
        if (
          this.$route.query.searchBy &&
          this.searchByOptions.includes(this.$route.query.searchBy) &&
          this.$route.query.filter
        ) {
          if (
            this.$route.query.page &&
            parseInt(this.$route.query.page) > 0 &&
            this.isNumeric(this.$route.query.page)
          ) {
            await this.doFetchFromRoute({
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
              page: parseInt(this.$route.query.page),
              limit: this.getItemsPerPage(),
              searchBy: this.$route.query.searchBy,
              filter: this.$route.query.filter,
            });
          } else {
            //   debugger
            this.page = 1;
            await this.doFetchFromRoute({
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
              page: 1,
              limit: this.getItemsPerPage(),
              searchBy: this.$route.query.searchBy,
              filter: this.$route.query.filter,
            });
          }
        } else {
          if (
            this.$route.query.page &&
            parseInt(this.$route.query.page) > 0 &&
            this.isNumeric(this.$route.query.page)
          ) {
            await this.doFetchFromRoute({
              page: parseInt(this.$route.query.page),
              limit: this.getItemsPerPage(),
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
            });
          } else {
            this.page = 1;
            await this.doFetch({
              page: 1,
              limit: this.getItemsPerPage(),
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
            });
          }
        }
        this.rowsOld = this.rows;
      }
    },
  },
  async mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardForward();
    };
  },
  async created() {
    this.setFacilityDetailsStarted();
    if (!this.mountedFacilityTypeTable) {
      this.doFetchFacilityType();
      this.setMountedFacilityTypeTable(true);
    }
    if (!this.mountedCityTable) {
      this.doFetchCity();
      this.setMountedCityTable(true);
    }
    this.setFacilityDetailsSuccess();

    this.loadingTable = true;
    this.page =
      this.$route.query.page && parseInt(this.$route.query.page) > 0
        ? parseInt(this.$route.query.page)
        : 1;
    this.search = this.$route.query.searchBy
      ? this.$route.query.searchBy
      : null;
    this.filter = this.$route.query.filter ? this.$route.query.filter : null;

    // this.setFacilityDetailsStarted()
    // if (!this.mountedFacilityTypeTable) {
    //   this.doFetchFacilityType();
    //   this.setMountedFacilityTypeTable(true);
    // }
    // if (!this.mountedCityTable) {
    //   this.doFetchCity();
    //   this.setMountedCityTable(true);
    // }
    // this.setFacilityDetailsSuccess()

    this.setRows([]);
    this.resetCursor();
    if (
      this.$route.query.searchBy &&
      this.searchByOptions.includes(this.$route.query.searchBy) &&
      this.$route.query.filter
    ) {
      if (
        this.$route.query.page &&
        parseInt(this.$route.query.page) > 0 &&
        this.isNumeric(this.$route.query.page)
      ) {
        await this.doFetchFromRoute({
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
          page: parseInt(this.$route.query.page),
          limit: this.getItemsPerPage(),
          searchBy: this.$route.query.searchBy,
          filter: this.$route.query.filter,
        });
      } else {
        this.page = 1;
        this.$router.push({
          query: {
            page: 1,
            searchBy: this.$route.query.searchBy,
            filter: this.$route.query.filter,
          },
        });
        await this.doFetchFromRoute({
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
          page: 1,
          limit: this.getItemsPerPage(),
          searchBy: this.$route.query.searchBy,
          filter: this.$route.query.filter,
        });
      }
    } else {
      if (
        this.$route.query.page &&
        parseInt(this.$route.query.page) > 0 &&
        this.isNumeric(this.$route.query.page)
      ) {
        await this.doFetchFromRoute({
          page: parseInt(this.$route.query.page),
          limit: this.getItemsPerPage(),
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
        });
      } else {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        this.page = 1;
        await this.doFetch({
          page: 1,
          limit: this.getItemsPerPage(),
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
        });
      }
    }
    this.rowsOld = this.rows;
    this.setMountedTable(true);
    this.loadingTable = false;

    // this.setFacilityDetailsStarted()
    // if (!this.mountedFacilityTypeTable) {
    //   this.doFetchFacilityType();
    //   this.setMountedFacilityTypeTable(true);
    // }
    // if (!this.mountedCityTable) {
    //   this.doFetchCity();
    //   this.setMountedCityTable(true);
    // }
    // this.setFacilityDetailsSuccess()
  },
};
