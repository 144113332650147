
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
export default {
    name:'app-facility-filter-modal',
    props:['visible'],
    data(){
        return{
            disabled: true,
            optionName: null,
            filterModel: null,
            filterId: null
        }
    },
    computed:{
         dialogVisible:{
            get(){
                return this.visible               
            },
            set(){
                // this.$emit('close',{ model:null,id: null })
            }
        },
        isRTL(){
            return i18n.locale == "ar"
        },
        ...mapGetters({
            cities: 'facilities/list/cities',
            facilityListedFor: 'facilities/list/facilityListedFor',
            facilityTypes: 'facilities/list/facilityTypes', 

        })
        
    },
  
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
        showOptionName(){         
          // debugger
          if(this.filterModel == 'city'){
            this.optionName = this.cities.find((ele) => ele.id == this.filterId)
          }
          if(this.filterModel == 'type'){
            this.optionName = this.facilityTypes.find((ele) => ele.id == this.filterId)
          }
          if(this.filterModel == 'listedFor'){
            this.optionName = this.facilityListedFor.find((ele) => ele.id == this.filterId)
          }
          if(this.isRTL){
              return this.optionName.name.ar
          }
          return this.optionName.name.en
        },
        filterOption(model,optionId,optionName){
          this.optionName = optionName
          // this.filterModel = model
            if(model === 'city'){
                this.expandedCity = false
                this.expandedListedFor = false
                this.expandedType = false
                this.$emit('close',{ model: 'city',id: optionId })
            }
            if(model === 'facilityListedFor'){
                this.expandedCity = false
                this.expandedListedFor = false
                this.expandedType = false
                this.$emit('close',{ model: 'facilityListedFor', id: optionId})
            }
            if(model === 'facilityType'){
                this.expandedCity = false
                this.expandedListedFor = false
                this.expandedType = false
                this.$emit('close',{ model: 'facilityType', id: optionId})
            }
            if(!model){
                this.expandedCity = false
                this.expandedListedFor = false
                this.expandedType = false
                this.$emit('close',{ model: null, id: null})
            }
        },
        ...mapActions({
            doFetchCity: 'jobs/list/doFetchCity'
        })
    },
    mounted(){
      this.filterModel = this.$route.query.searchBy ? this.$route.query.searchBy : null
      this.filterId = this.$route.query.filter ? this.$route.query.filter : null
    }
}
