
// import {i18n} from '@/i18n';
import { mapGetters } from 'vuex';
export default {
    name:'app-delete-dialog',
props:['deleteDialog','service'],
computed:{
  ...mapGetters({
          prescriptionLoading: 'prescriptions/form/saveLoading',
          jobLoading:'jobs/form/saveLoading',
          facilityLoading:'facilities/form/saveLoading',
          commentLoading: 'prescriptions/destroy/loading',
      }),
    deleteModel:{
        get(){
            return this.deleteDialog
        },
        set(){
            this.$emit('close')
        }
    }
},
methods:{
 
  i18n(key, args) {
      return this.$t(key, args);
    },
    deleteItem(){
      this.$emit('deleteItem')
    },
    closeDialog(){
        // console.log('id',this.id)
        this.$emit('close')
    }
},
mounted(){
    
}

}
