
// import firebase from 'firebase/app'
import "firebase/firestore";
import { mapGetters } from "vuex";
// import i18n from '@/vueI18n'
export default {
  name: "app-filter-facilities",
  props: ["searchInputAnimation", "searchBy", "filter"],
  data() {
    return {
      filterTab: "",
      optionName: null,
      filterModel: null,
      filterId: null,
      // cities:[],
      // listedFor:[],
      // types:[],
    };
  },
  computed: {
    ...mapGetters({
      cities: "facilities/list/cities",
      listedFor: "facilities/list/facilityListedFor",
      types: "facilities/list/facilityTypes",
    }),
    isRTL() {
      return localStorage.getItem("language") == "ar";
    },
  },

  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },

    showOptionName() {
      if (this.filterModel == "city") {
        this.optionName = this.cities.find((ele) => ele.id == this.filterId);
      }
      if (this.filterModel == "facilityType") {
        this.optionName = this.types.find((ele) => ele.id == this.filterId);
      }
      if (this.filterModel == "facilityListedFor") {
        this.optionName = this.listedFor.find((ele) => ele.id == this.filterId);
      }
      if (this.isRTL) {
        return this.optionName.name.ar;
      }
      return this.optionName.name.en;
    },

    filterOption(model, optionId, optionName) {
      this.filterModel = model;
      this.optionName = optionName;
      this.filterId = optionId;
      if (model === "city") {
        this.$emit("close", { model: "city", id: optionId });
      }
      if (model === "facilityListedFor") {
        this.$emit("close", { model: "facilityListedFor", id: optionId });
      }
      if (model === "facilityType") {
        this.$emit("close", { model: "facilityType", id: optionId });
      }
      if (!model) {
        this.$emit("close", { model: null, id: null });
      }
    },
    removeFilter() {
      this.filterModel = null;
    },
  },
  async mounted() {
    //   console.log(this.searchBy);
    //   console.log(this.filter);
    //   this.filterModel = this.$route.query.searchBy ? this.$route.query.searchBy : null
    this.filterId = this.$route.query.filter ? this.$route.query.filter : null;
    if (this.$route.query.searchBy == "city") {
      this.filterModel = "city";
      this.filterTab = "city";
    }
    if (this.$route.query.searchBy == "type") {
      this.filterModel = "facilityType";
      this.filterTab = "facilityType";
    }
    if (this.$route.query.searchBy == "listedFor") {
      this.filterModel = "facilityListedFor";
      this.filterTab = "facilityListedFor";
    }

    //   else{
    //       this.filterModel = null
    //   }
  },
};
