
import DeleteDialog from '@/modules/services/components/delete-dialog.vue';
import { mapActions, mapGetters } from 'vuex';
// import {i18n} from '@/i18n';
// import firebase from 'firebase/app';
export default {
    name:'app-delete-edit',
    props:['service','id','job','facility','prescription','prescId'],
    data(){
        return{
           deleteDialog:false 
        }
        
    },
    components:{
        [DeleteDialog.name]: DeleteDialog
    },
   computed:{
     ...mapGetters({
       is_screen_xs:'layout/is_screen_xs',
       is_screen_sm:'layout/is_screen_sm',
       is_screen_md:'layout/is_screen_md',

     })
   },
    methods:{
      ...mapActions({
        doUpdateJob: 'jobs/form/doUpdate',
        doUpdateFacility: 'facilities/form/doUpdate',
        doUpdatePrescription:'prescriptions/form/doUpdate',
        doDestroyComment: 'prescriptions/destroy/doDestroyComment'
      }),
      i18n(key, args) {
      return this.$t(key, args);
    },
        openDeleteDialog(){
          this.deleteDialog = true
          this.$emit('openDeleteDialog')
          // console.log('id',this.id)
          // console.log('type',this.type)
          
        },
        async deleteItem(){
           if(this.service == 'facility'){
              let facility = Object.assign({}, this.facility);
              // // let facility = this.facility
              facility.postStatus = "deleted"
              facility.createdAt = new Date(facility.createdAt.seconds * 1000)
              delete facility.updatedAt
              delete facility.id
              delete facility.creator
              delete facility.createdAtUpdated
              delete facility.listedForName
              delete facility.listedFor
              await this.doUpdateFacility({id: this.id,values: facility })
              this.$emit('redirect',this.id)
               
            }
            if(this.service == 'prescription'){
                let prescription = Object.assign({}, this.prescription);
                prescription.postStatus = "deleted"
                prescription.createdAt = new Date(prescription.createdAt.seconds * 1000)
                delete prescription.updatedAt
                delete prescription.id
                delete prescription.creator
                delete prescription.commentsLength
                await this.doUpdatePrescription({id: this.id, values: prescription })
                this.$emit('redirect',this.id)
                
            }
            if(this.service == 'job'){
              // debugger
              let job = Object.assign({}, this.job);
              // let job = this.job
              job.postStatus = "deleted"
              job.createdAt = new Date(job.createdAt.seconds * 1000)
              job.dueDate = new Date(job.dueDate.seconds * 1000)
              delete job.updatedAt
              delete job.id
              delete job.creator
              delete job.createdAtUpdated
              await this.doUpdateJob({id: this.id,values: job })
              this.$emit('redirect',this.id)
              // debugger
              
            }
            if(this.service == 'comment'){
              let value = {
                id: this.id,
                prescId: this.prescId
              }

              await this.doDestroyComment(value)
              this.$emit('removeElement',this.id)
            }
            // console.log('###id',this.id)
            this.deleteDialog = false
        },
        close(){
            this.deleteDialog = false
            this.$emit('closeDeleteDialog')
        },
        goToEdit(){
        // console.log('in edit')
          if(this.service == 'facility'){
             this.$router.push(
              {
                path:`/add-facility/edit/${this.id}`,
                // query: {edit: true}
              })
            // this.$router.push({ path: '/add-facility', query: { 
            // edit: true,
            // id:this.id,
            // name:'company',
            // spaceArea:'test1',
            // typeModel:'test2',
            // listedForModel:'alex',
            // cityModel:'2121',
            // regionModel:'2121',
            // statusModel:'ghfsfdf',
            // expectedPrice:'fddfs',
            // averageIncome:'324322433',
            // phoneNumber:'2332',
            // description:'sdffdsdfsdfs', }
            // }
            // )
          }
          if(this.service == 'job'){
            this.$router.push(
              {
                path:`/add-job/edit/${this.id}`,
                // query: {edit: true}
              })
            // this.$router.push({ path: '/add-job', query: { 
            // edit: true,
            // id:this.id,
            // companyName:'company',
            // workplaceModel:'test1',
            // positionModel:'test2',
            // cityModel:'alex',
            // regionModel:'smooha',
            // priceRangeBegin:'2121',
            // priceRangeEnd:'2121',
            // description:'ghfsfdf',
            // address:'fddfs',
            // phone:'324322433',
            // time:'2332',
            // email:'sdffdsdfsdfs', }
            // }
            // )
        }
          if(this.service == 'prescription'){
            this.$router.push(`/edit-prescription/${this.id}`)
          }
          if(this.service == 'comment'){
            this.$emit('editComment',this.id)
          }
          
        }

        
    }
    
}
